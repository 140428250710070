<template>
    <div
        @touchmove.prevent
        class="page"
    >
        <scroller>
            <div class="content">
                <img
                    class="img"
                    src="@/assets/login_success.png"
                    alt=""
                >
                <div class="success">注册成功</div>
                <div class="dec">恭喜您，已成功注册平台账号点击</div>
                <div class="dec"> 下方按钮前往下载APP</div>
                <div
                    class="btn"
                    @click="downloadApp"
                >前往下载APP</div>
            </div>
        </scroller>
        <div class="model" v-if="hide">
      <img src="@/assets/android.png" v-if="tabhiden == 'android'" alt="" />
      <img src="@/assets/ios.png" v-if="tabhiden == 'ios'" alt="" />
    </div>
    </div>
</template>

<script>
import { download } from './js/download';
export default {
    components: {},
    data() {
        //这里存放数据
        return {
      hide: false,
      tabhiden: "",
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //方法集合
    methods: {
        downloadApp() {
            download();
        },
        getSystem() {
            let system = ''
            var u = navigator.userAgent
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
            var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isAndroid) {
                return "android"
            }
            if (isIOS) {
                return "ios"
            }
            return system;
        }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        const browser = {
    versions: (function () {
        const u = navigator.userAgent,
            app = navigator.appVersion;
        return {
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        };
    })(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase(),
};
if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
  var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //在微信中打开
    this.hide=true
  }
}
        var tab = this.getSystem();
    this.tabhiden = tab == "android" ? "android" : "ios"
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 -   挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
}
</script>
<style lang='scss' scoped>
@import "./index.scss";
</style>
